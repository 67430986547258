/* * * * * * * * * *
+320px :: 20em
+480px :: 30em
+600px :: 37.50em
+768px :: 48em
+992px :: 62em
+1024px :: 64em
+1140px :: 71.250em
+1224px :: 76.5em
+1382px :: 86.375em
* * * * * * * * * */
/* Media Queries*/
/* Phones */
/* Tablets*/
/* Tablets Horizontal*/
/* Desktops, slightly less large devices */
/* Desktops, large devices */
/* Desktops, very large devices */
/* Desktops, top navigation */
/* px and rem */
/* -----------------------------
   FUNCTIONS
   ----------------------------- */
/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/
/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.
@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
#main {
  background-color: #FFFFFF;
  font-size: 17px;
}

.heroV2 {
  padding: 60px 0 350px;
  background-size: auto 350px;
  background-position: center bottom;
}
@media screen and (min-width: 48em) {
  .heroV2 {
    height: auto;
    padding: 60px 0;
    background-size: auto 100%;
    background-position: calc(50% + 140px) bottom;
  }
}
@media screen and (min-width: 71.25em) {
  .heroV2 {
    padding: 96px 0;
    background-position: calc(50% + 300px) bottom;
  }
}
.heroV2 .zone-hero-texte {
  padding: 0;
}
@media screen and (min-width: 48em) {
  .heroV2 .zone-hero-texte {
    max-width: 320px;
  }
}
@media screen and (min-width: 71.25em) {
  .heroV2 .zone-hero-texte {
    max-width: 550px;
  }
}
.heroV2 .zone-hero-texte .hero-titre {
  margin: 0 0 16px;
  font-size: 60px;
}
.heroV2 .zone-hero-texte .hero-sous-titre {
  max-width: none;
  font-weight: 400;
}
.heroV2 .zone-hero-texte .hero-separateur {
  display: none;
}
.heroV2 .zone-hero-texte .liens-store {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}
.heroV2 .zone-hero-texte .liens-store a {
  margin: 0;
}
.heroV2 .zone-hero-texte .liens-store .rating {
  margin: 6px auto 0;
}

.list-bullet li {
  position: relative;
  padding-left: 33px;
  margin-bottom: 9px;
}
.list-bullet li:before {
  border: none;
  content: "\e93d";
  color: #2062d4;
  font-size: 24px;
  font-family: IA_icons;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;
}

header {
  max-width: 100% !important;
  background-color: #003da5 !important;
}

.grille-zone-titre .bloc-contenu-titre {
  margin-bottom: 36px !important;
  margin-top: 60px;
}
.grille-zone-titre .bloc-contenu-titre h2 {
  font-size: 32px;
  font-weight: 800;
}

.grille-zone-produits .grille-bloc-ass-coll,
.grille-zone-produits .grille-bloc-erc,
.grille-zone-produits .grille-bloc-eri {
  border-radius: 24px;
  height: 100%;
}
.grille-zone-produits .grille-bloc-ass-coll .col-1-1,
.grille-zone-produits .grille-bloc-erc .col-1-1,
.grille-zone-produits .grille-bloc-eri .col-1-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
}
.grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-bloc-titre-couleur,
.grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-bloc-titre-couleur,
.grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-bloc-titre-couleur {
  display: block;
  margin-bottom: 24px;
}
@media screen and (min-width: 600px) {
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-bloc-titre-couleur,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-bloc-titre-couleur,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-bloc-titre-couleur {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1140px) {
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-bloc-titre-couleur,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-bloc-titre-couleur,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-bloc-titre-couleur {
    display: block;
    margin-bottom: 24px;
  }
}
.grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-bloc-titre-couleur .icone-bloc-contenu,
.grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-bloc-titre-couleur .icone-bloc-contenu,
.grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-bloc-titre-couleur .icone-bloc-contenu {
  z-index: 2;
  border-radius: 10px;
  width: 55px;
  height: 55px;
  fill: #003da5;
  border: 3px solid #003da5;
  margin-left: 16px;
  margin-top: 36px;
  margin-bottom: 16px;
}
@media screen and (min-width: 400px) {
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-bloc-titre-couleur .icone-bloc-contenu,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-bloc-titre-couleur .icone-bloc-contenu,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-bloc-titre-couleur .icone-bloc-contenu {
    margin-left: 32px;
    margin-top: 36px;
  }
}
.grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-bloc-titre-couleur h2,
.grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-bloc-titre-couleur h2,
.grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-bloc-titre-couleur h2 {
  font-weight: 800;
  color: #003da5;
  font-size: 26px;
  margin: 0 16px;
}
@media screen and (min-width: 1141px) {
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-bloc-titre-couleur h2,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-bloc-titre-couleur h2,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-bloc-titre-couleur h2 {
    margin-left: 32px;
    margin-right: 32px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1140px) {
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-bloc-titre-couleur h2,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-bloc-titre-couleur h2,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-bloc-titre-couleur h2 {
    padding-top: 45px;
  }
}
@media screen and (min-width: 400px) and (max-width: 600px) {
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-bloc-titre-couleur h2,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-bloc-titre-couleur h2,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-bloc-titre-couleur h2 {
    margin-left: 32px;
  }
}
.grille-zone-produits .grille-bloc-ass-coll .col-1-1 .titre-ass,
.grille-zone-produits .grille-bloc-erc .col-1-1 .titre-ass,
.grille-zone-produits .grille-bloc-eri .col-1-1 .titre-ass {
  margin-right: 60px;
}
@media screen and (min-width: 768px) {
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .titre-ass,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .titre-ass,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .titre-ass {
    margin-right: 160px;
  }
}
.grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-texte-assurance-collective,
.grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-texte-erc,
.grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-texte-eri,
.grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-texte-assurance-collective,
.grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-texte-erc,
.grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-texte-eri,
.grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-texte-assurance-collective,
.grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-texte-erc,
.grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-texte-eri {
  height: 100%;
  max-height: 400px;
  background-color: #FFFFFF;
  border-radius: 24px;
  padding: 36px 16px;
}
@media screen and (min-width: 400px) {
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-texte-assurance-collective,
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-texte-erc,
  .grille-zone-produits .grille-bloc-ass-coll .col-1-1 .bloc-contenu-texte-eri,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-texte-assurance-collective,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-texte-erc,
  .grille-zone-produits .grille-bloc-erc .col-1-1 .bloc-contenu-texte-eri,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-texte-assurance-collective,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-texte-erc,
  .grille-zone-produits .grille-bloc-eri .col-1-1 .bloc-contenu-texte-eri {
    padding: 36px 32px;
  }
}

.grille-bloc-ass-coll {
  background-color: #d7eeec;
}
.grille-bloc-ass-coll .bloc-contenu-texte-assurance-collective {
  border: solid 2px #d7eeec;
}

.grille-bloc-erc {
  background-color: #e6e7f3;
}
.grille-bloc-erc .bloc-contenu-texte-erc {
  border: solid 2px #e6e7f3;
}

.grille-bloc-eri {
  background-color: #e5f3d5;
}
.grille-bloc-eri .bloc-contenu-texte-eri {
  border: solid 2px #e5f3d5;
}

.grille-zone-apps .grille-applications .bloc-contenu-bloc-droite .conteneur-images,
.grille-zone-apps .grille-applications .bloc-contenu-bloc-gauche .conteneur-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}
.grille-zone-apps .grille-applications .bloc-contenu-bloc-droite,
.grille-zone-apps .grille-applications .bloc-contenu-bloc-centre,
.grille-zone-apps .grille-applications .bloc-contenu-bloc-gauche {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.grille-zone-apps .grille-applications .bloc-contenu-bloc-droite img,
.grille-zone-apps .grille-applications .bloc-contenu-bloc-centre img,
.grille-zone-apps .grille-applications .bloc-contenu-bloc-gauche img {
  height: 60px;
  width: 60px;
}
.grille-zone-apps .grille-applications .bloc-contenu-bloc-droite a,
.grille-zone-apps .grille-applications .bloc-contenu-bloc-centre a,
.grille-zone-apps .grille-applications .bloc-contenu-bloc-gauche a {
  margin-top: auto;
}
.grille-zone-apps .grille-applications .bloc-contenu-bloc-droite .material-icons-outlined,
.grille-zone-apps .grille-applications .bloc-contenu-bloc-centre .material-icons-outlined,
.grille-zone-apps .grille-applications .bloc-contenu-bloc-gauche .material-icons-outlined {
  margin-left: 3px;
}